import { template as template_d26c8dc9dca146078d18182b9059f3e3 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_d26c8dc9dca146078d18182b9059f3e3(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
