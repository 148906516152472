import { template as template_3a130ceaad6745b3a17c9d8f8adf75b4 } from "@ember/template-compiler";
const FKText = template_3a130ceaad6745b3a17c9d8f8adf75b4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
